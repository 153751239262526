<template>
  <div :class="['project-subtypes', { loader: loader }]">
    <ui-loader v-model="loader" />
    <UiNotification v-model="showNotification" :message="message" />
    <Table
        v-if="!loader"
        :title="$t('project-subtypes')"
        :actionText="$t('add-subtype')"
        showAction
        trash-is-hidden
        :data="getEsgSubtypes.data"
        :descriptionList="descriptionList"
        hideEditIcon
        @changeRowField="changeRowField($event)"
        @addRowField="addRowField($event)"
        @deleteRowField="deleteRowField($event)"
        :sort="true"
        :isGlobeIcon="true"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UiLoader from "@/components/ui/UiLoader";
import UiNotification from "@/components/ui/UiNotification";

export default {
  name: "IncidentTypes",
  components: {
    UiNotification,
    UiLoader,
    Table: () => import("@/components/ui/UiTable"),
  },

  data() {
    return {
      descriptionList: [
        {title: this.$t('title'), width: 440}
      ],
      loader: true,
      showNotification: false,
      message: ''
    }
  },
  methods: {
    ...mapActions([
      'esgSubtypes',
      'changeEsgSubtype',
      'addEsgSubtype',
      'deleteEsgSubtype'
    ]),
    changeRowField(data) {
      this.loader = true;

      this.changeEsgSubtype({
        name: data.name,
        id: data.id,
        ord: +data.ord,
        nameInt: {
          en: data.nameIntEn
        }
      }).then(() => {
        this.esgSubtypes()
        setTimeout(() => {
          this.loader = false
          this.message = this.$t('subtype-successfully-updated')
          this.showNotification = true
        }, 500)
      })
    },

    addRowField(data) {
      this.loader = true;

      this.addEsgSubtype({
        name: data.name,
        ord: +data.ord
      }).then(() => {
        this.esgSubtypes()
        setTimeout(() => {
          this.loader = false
          this.showNotification = true
          this.message = this.$t('subtype-successfully-added')
        }, 500)
      })
    },

    deleteRowField (data) {
      this.loader = true;

      this.deleteEsgSubtype({
        id: data.id
      }).then(() => {
        this.esgSubtypes()
        setTimeout(() => {
          this.loader = false
          this.showNotification = true
          this.message = this.$t('subtype-successfully-deleted')
        }, 500)
      })
    }
  },

  computed: {
    ...mapGetters(['getEsgSubtypes'])
  },

  mounted() {
    this.esgSubtypes().then(() => {
      this.loader = false
    })
  }
}
</script>

<style lang="scss" scoped>
.project-subtypes {
  width: 100%;
  max-width: 1000px;

  &.loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>